import styles from "./styles.module.scss";
import Link from "next/link";
import { useContext, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useCore } from "@/core-nextv3/core/core";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import logo from "../../../../public/assets/img/logo.png"
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { subTranslate } from "@/bet-nextv1/utils/subTranslate";
import { addPointsTransfer } from "@/core-nextv3/bet/bet.api";
import { utcToZonedTime } from "date-fns-tz";
import Types from "@/core-nextv3/type";
import { THEME_SETTING, TRANSFER_SETTING } from "@/setting/setting";
import { useSubCore } from "@/hooks/useSubCore";
import { motion, useAnimationFrame, useMotionValue, useScroll, useSpring, useTransform, useVelocity, wrap } from "framer-motion";
import { CircularProgress } from "@mui/material";

export const HeaderDesktop = ({ account, setOpenLoginModal, setTypeLogin, setDisableClose, handleLogoutClick, transferPoints }: any) =>
{
    const { t }                                                     = useTranslation();
    const router                                                    = useRouter();
    const { user, loading }                                         = useContext(AuthContext);
    const { awaitLoadingHTTP, setAwaitLoadingHTTP, isLoadingUser }  = useCore();
    const { setOpenMessageModal, setMessage, setMessageModalIcon, } = useSubCore()
    

    const handleLogoLink = async (path) => 
    {

        if ( router.asPath === "/") 
        {
            router.reload();
        }
        else 
        {
            await router.push(path);
        }
    }


    function ParallaxText({ children, baseVelocity = 100 }: ParallaxProps) 
    {
        const baseX          = useMotionValue(0);
        const { scrollY }    = useScroll();
        const scrollVelocity = useVelocity(scrollY);
        const smoothVelocity = useSpring(scrollVelocity, {
            damping   : 50,
            stiffness : 400
        });
        const velocityFactor = useTransform(smoothVelocity, [ 0, 1000 ], [ 0, 5 ], {
            clamp : false
        });

        /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
        const x = useTransform(baseX, (v) => `${wrap(-110, 240, v)}%`);

        const directionFactor = useRef<number>(1);
        useAnimationFrame((t, delta) => 
        {
            let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

            /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
            if (velocityFactor.get() < 0) 
            {
                directionFactor.current = -1;
            }
            else if (velocityFactor.get() > 0) 
            {
                directionFactor.current = 1;
            }

            moveBy += directionFactor.current * moveBy * velocityFactor.get();

            baseX.set(baseX.get() + moveBy);
        });

        return (
            <div className={styles.parallax}>
                <motion.div className={styles.scroller} style={{ x }}>
                    <span style={{ color : "white" }}>{children} </span>
                </motion.div>
            </div>
        );
    }

    return (
        <div className={styles.headerDesktop}>
            <div className={styles.content}>
                <div className={styles.menuLeft}>
                    <div className={styles.logoMenu}>
                        <a onClick={() => handleLogoLink("/")} className='logo'>
                            <img 
                                src={account?.logo?._url || logo} 
                                alt='logo' 
                            />
                        </a>
                    </div>

        
                </div>
        
                <ParallaxText baseVelocity={40} >
                    {THEME_SETTING.scrollingText}
                </ParallaxText>
        
                <div className={styles.left}>
                    {user &&
                        <ul className={styles.mainMenu}>

                            {/* <div className={styles.subMenuGridItem}>
                                <CiClock1 />
                                <Clock />
                            </div> */}
                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/deposito_yellow.png' alt='' />
                                    {user.name} 님
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    // onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/payout_yellow.png' alt='' />
                                    {t("Montante retido")}
                                    <small>
                                        {user?.sport
                                            ? new Intl.NumberFormat("en").format(user?.sport)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => transferPoints()}
                                    // onClick={() => redirectTo("/", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/movimentacao_yellow.png' alt='' />
                                    {t("point")}
                                    <small>
                                        {user?.points
                                            ? new Intl.NumberFormat("en").format(user?.points)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    // onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/customer_yellow.png' alt='' />
                                    {subTranslate("Dinheiro do Cassino", t)}
                                    <small>
                                        {user?.casino
                                            ? new Intl.NumberFormat("en").format(user?.casino)
                                            : 0}{" "}
                                    </small>
                                </a>
                            </div>

                            {/* <div className={styles.subMenuGridItemButton}>
                         
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                                >
                                    {t("Refresh")}
                                </a>
                            </div> */}

                            <div className={styles.subMenuGridItemButton}>
                        
                                <Link
                                    className={styles.title}
                                    href='/deposit/?tabIndex=2'
                                    // onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                                >
                                    {/* <img src='/assets/img/event_yellow.png' alt='' /> */}
                                    {t("Transferencias")}
                                </Link>
                            </div>

                            {/* <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/notice_yellow.png' alt='' />
                                    {t("Avisos / Regras")}
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/betting-history_yellow.png' alt='' />
                                    {t("Histórico de Apostas")}
                                </a>
                            </div>

                            <div className={styles.subMenuGridItem}>
                        
                                <a
                                    className={styles.title}
                                    onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}
                                >
                                    <img src='/assets/img/cash-history_yellow.png' alt='' />
                                    {t("Histórico de Cash")}
                                </a>
                            </div> */}
                        </ul>
                    }

        
                    
                    {awaitLoadingHTTP === false && user ? (
                        <div className={styles.dashboarWrap}>
            
                            <button onClick={() => handleLogoutClick()}>{t("Logout")}</button>
                        </div>
                    ) : <div className={styles.menuBtnGrp}>
                        {!loading && !awaitLoadingHTTP && (
                            <>
                                <button
                                    className={styles.buttonNavigation}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("login"),
                                        setDisableClose(false)
                                    )}
                                >
                                    <span>{t("Logar")}</span>
                                </button>

                                <button
                                    className={styles.buttonNavigationTow}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("register"),
                                        setDisableClose(false)
                                    )}
                                >
                                    <span className='rela'>{t("Cadastrar")}</span>
                                </button>
                            </> 
                        )}
                    </div>}
                </div>
            </div>
        </div>
    );
}